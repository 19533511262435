<template>
  <div class="recommend">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">For you</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="top div-bg">
      <img width="100%" :src="require(`./img/1.png`)" alt="" />
      <div class="bg"></div>
    </div>

    <productCard />
  </div>
</template>

<script>
import productCard from "@/components/product-card";
export default {
  components: { productCard },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .top {
    position: sticky;
    top: 5.5rem;
    z-index: 999;
  }
}
</style>
